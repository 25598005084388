/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Header from "../shared/Header"
import Footer from "./Footer"
import ScrollToTopButton from "../shared/ScrollToTopButton"

const query = graphql`
  query {
    logo: file(relativePath: { eq: "do/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 50)
      }
    }
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(query)
  return (
    <>
      {/* <Header logo={getImage(data.logo)} path="do" /> */}
      <div className="overflow-x-hidden">
        <div className="mx-auto max-w-[960px] px-4 h-screen flex justify-center items-center">
          <main>{children}</main>
        </div>
      </div>
      {/* <Footer /> */}
      <ScrollToTopButton target="do" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
