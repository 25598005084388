import React from "react"
import HeadingL from "../../components/shared/HeadingL"
import Layout from "../../components/do/Layout"
import ProgramActivityExample from "../../components/do/ProgramActivityExample"
import ProgramSchedule from "../../components/do/ProgramSchedule"
import SEO from "../../components/Seo"
import { PAGE } from "../../constants"

const program = () => {
  return (
    <Layout>
      <SEO title={PAGE.title.program} siteTitle="Do" />
      <HeadingL title={PAGE.title.program} target="do" />
      <ProgramSchedule />
      <ProgramActivityExample />
    </Layout>
  )
}

export default program
