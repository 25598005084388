import React from "react"
import HeadingM from "../shared/HeadingM"
import Schedule from "../shared/program/Schedule"

const program = () => {
  const items = [
    {
      day: "月・火・水・木・金",
      contents:
        "決まったプログラムは隣接するカフェでの活動のみで、月毎にスケジュール調整しています。",
    },
    {
      day: "祝日",
      contents:
        "祝日はイベントとして活動しています。\n記念日に因んだ活動を取り入れています。",
    },
  ]
  return (
    <div>
      <HeadingM title="スケジュール" target="do" />
      <Schedule items={items} target="do" />
    </div>
  )
}

export default program
