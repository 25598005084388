import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import ActivityExample from "../shared/program/ActivityExample"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  query {
    yoga: file(relativePath: { eq: "do/activity/yoga.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    enjoy: file(relativePath: { eq: "do/activity/enjoy.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    work: file(relativePath: { eq: "do/activity/work2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
  }
`

const ProgramActivityExample = () => {
  const data = useStaticQuery(query)
  const items = [
    {
      title: "Doスポーツ",
      description:
        "バスケットボール、バトミントン、ランニング etc.\n日頃から体幹を鍛えるトレーニングを取り入れた活動をDo！",
      image: getImage(data.yoga),
    },
    {
      title: "Doワーク",
      description:
        "働くことの楽しさ、大切さを自然と身につけ、一人ひとりのこれからの生きる力をサポートできるように、隣接されたcaféでお仕事をDo！（caféでお手伝いをさせていただきます。）",
      image: getImage(data.work),
    },
    {
      title: "Doエンジョイ",
      description:
        "楽しいから始められる。楽しいから見つけられる。楽しいから続けられる。そんな居場所を一緒に作りましょう。みんなで楽しいをDo！",
      image: getImage(data.enjoy),
    },
  ]
  return (
    <div>
      <HeadingM title="活動事例" target="do" />
      <ActivityExample items={items} />
    </div>
  )
}

export default ProgramActivityExample
